import { Button, Card, Col, Layout, Row } from "antd";
import { UserSwitchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InstructorCard } from "../../PlannerPage/InstructorCard";
import { ExamCard } from "./ExamCard";
import { LearningStatsCard } from "./LearningStatsCard";
import { LessonsHistoryTable } from "./LessonsHistoryTable";
import { RatingSection } from "./RatingSection";
import { UpcomingLessonsTable } from "./UpcomingLessonsTable";

export const DrivingSummary = (props: any) => {
    const { t } = useTranslation();
    const {
        upcomingLessons,
        examSlot,
        lastInstructor,
        lastLesson,
        student,
        loading,
        goToCalendar,
        isMobile,
        studentHistory,
        studentExamHistory,
        subjects,
        examSubjects,
        removeLesson,
        studentRating,
        gradeLVL,
        studentTestDriveHistory
    } = props;

    useEffect(() => {
        console.log(examSlot)
    }, [examSlot]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <Row gutter={24}>
        <Col span={24} lg={!!student.category.full_eval ? 16 : 24} style={{ marginBottom: 20 }}>
            <LearningStatsCard isMobile={isMobile} student={student} />
        </Col>
        {
            !!student.category.full_eval
            && <Col span={24} lg={8} style={{ marginBottom: 20 }}>
                <Card>
                    <RatingSection rating={studentRating} total={subjects.length} gradeLVL={gradeLVL} />
                </Card>
            </Col>
        }
        {
            examSlot.length > 0
            && <Col span={24} style={{ marginBottom: 20 }}>
                <ExamCard examSlot={examSlot} />
            </Col>
        }
        <Col span={24} style={{ marginBottom: 20 }}>
            <UpcomingLessonsTable
                isMobile={isMobile}
                upcomingLessons={upcomingLessons}
                lastLesson={lastLesson}
                goToCalendar={goToCalendar}
                removeLesson={removeLesson}
                student={student}
            />
        </Col>
        {
            (studentTestDriveHistory && studentTestDriveHistory.length > 0) &&
            <Col span={24} style={{ marginBottom: 30 }}>
                <LessonsHistoryTable
                    isMobile={isMobile}
                    studentHistory={studentTestDriveHistory}
                    subjects={examSubjects}
                    testDrive
                    all
                />
            </Col>
        }
        <Col span={24} style={{ marginBottom: 20 }}>
            <LessonsHistoryTable
                student={student}
                isMobile={isMobile}
                studentHistory={studentExamHistory}
                subjects={examSubjects}
                exam
            />
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
            <LessonsHistoryTable
                student={student}
                isMobile={isMobile}
                studentHistory={studentHistory}
                subjects={subjects}
            />
        </Col>
        <Col span={24}>
            <Card
                className='responsive-card'
                style={{ marginBottom: 20 }}
                title={
                    <div>
                        <Row>
                            {t('driving.my_instructor')}
                            <Link to="/planner/select-instructor" style={{ marginLeft: 20 }}>
                                <Button
                                    icon={<UserSwitchOutlined />}
                                    type="primary"
                                    style={{ whiteSpace: "normal" }}
                                >
                                    {lastInstructor != null ? t('driving.apply_for_lesson_to_other_instructor') : t('driving.select_instructor')}
                                </Button>
                            </Link>
                        </Row>
                    </div>
                }>
                <Col span={24}>
                    {lastInstructor !== null
                        ? <InstructorCard
                            callback={goToCalendar}
                            key={`instructor-card-${lastInstructor.instructor_id}`}
                            instructor={lastInstructor}
                            filter={lastLesson !== undefined
                                ? lastLesson
                                : student
                            }
                            type="inline-small"
                            loading={loading}
                        />
                        : <></>}
                </Col>
            </Card>
        </Col>
    </Row>
}