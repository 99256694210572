import { Card, Row, Col, Button, Modal, Tag } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CheckpointHorizontal } from "../Custom/Checkpoints/CheckpointHorizontal";
import { MissedWarning } from "./MissedWarning";
import { ProgressReel } from "../e-learning/components/ProgressReel";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { Link, useHistory } from "react-router-dom";
import { CountDownTimer } from "../e-learning/common/CountDownTimer";

export const TheoryInfoCard = (props: any) => {
    const history = useHistory();
    const { t } = useTranslation();
    const {
        // allLessons,
        attendedLessons,
        foreignLessons,
        // allSignedLessons,
        student,
        getNotifications,
        getNextLesson,
        refetch,
        cities,
        haveElearning,
        eLearningModules,
        theoryExams,
        refetchExams,
        refreshBalance
    } = props;
    // const [status, setStatus] = useState({} as any);
    const [nextLesson, setNextLesson] = useState({} as any);
    const [nextTheoryExam, setNextTheoryExam] = useState({} as any);
    const [studentAgeValidation, setStudentAgeValidation] = useState({} as any);
    const [studentCategory, setStudentCategory] = useState({} as any);
    const [fullTestCategory, setFullTestCategory] = useState(false);
    // const lessonsDone = () => {
    //     // console.log(attendedLessons)
    //     const count = attendedLessons.filter((lesson: any) => lesson.attained !== 0 || lesson.skipped !== 0).length - 1;
    //     // console.log(count)
    //     // return attendedLessons[attendedLessons.length - 1]?.lesson_counter - 1
    //     return count;
    // };

    // const fetchStudentServices = async () => {
    //     http.get(`student-study-group-services/get-services/${student.only_study_group[0].id}`).then((response: any) => {
    //         // console.log(response)
    //     })
    // };

    const getFullTestExamCategories = () => {
        http.get(`get-csdd-test-categories`).then((response: any) => {
            setFullTestCategory(response.data.some((el: any) => el.id === student.category_id))
        })
    }

    // const lessonsAttended = () => {
    //     const ids = attendedLessons.map((item: any, index: any) => {
    //         if (item.attained) {
    //             return index + 1
    //         }
    //         return;
    //     }).filter((item: any) => !(item === undefined))
    //     return ids;
    // }

    const lessonsMissed = () => {
        const ids = attendedLessons.map((item: any, index: any) => {
            if (item.skipped) {
                return item.lesson_counter
                return index + 1
            }
            return;
        }).filter((item: any) => !(item === undefined))
        return ids;
    }

    // const lessonSkipped = () => {
    //     const firstNr = attendedLessons[0]?.lesson_counter
    //     let ids = [];
    //     for (let i = 1; i < firstNr; i++) {
    //         ids.push(i);
    //     }
    //     return ids;
    // }

    const NextLesson = () => {
        return <>
            {/* <small>Nākamā nodarbība Nr.{nextLesson?.normal_id} ({nextLesson?.name})</small> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    {moment(nextLesson?.lesson_date, 'YYYY-MM-DD').format("dddd, D. MMMM")},&nbsp;
                    {moment(nextLesson?.time_from, 'HH:mm:ss').format("HH:mm")} - {moment(nextLesson?.time_to, 'HH:mm:ss').format("HH:mm")}
                </div>

                {
                    nextLesson.zoom_link
                        ? <div>
                            <a href={nextLesson.zoom_link} target="_blank" rel='noopener noreferrer'>ZOOM</a>
                        </div>
                        : ""
                }

            </div>
            {
                nextLesson.address
                    ? <div>
                        <p>{nextLesson.address}</p>
                    </div>
                    : nextLesson.is_online
                        ? "Tiešsaistē"
                        : <></>
            }
        </>
    }

    const getStudentAgeValidation = () => {
        http.get(`get-valid-theory-exam-age`).then((response: any) => {
            //console.log((response))
            setStudentAgeValidation(response.data)
        })
    }

    const getNextTheoryExam = (exams: any) => {
        const upcomingExams = exams.filter((exam: any) => {
            if (moment(exam.exam_date).isSame(moment(), "date")) {
                return !exam.status.includes("cancelled")
                    // && moment().isSameOrAfter(moment(exam.time_from, "HH:mm:ss").subtract(5, "minutes"), "minute")
                    && moment().isBefore(moment(exam.time_to, "HH:mm:ss").add(5, "minutes"), "minute")
                    && !exam.status.includes("exam")
            } else {
                return !exam.status.includes("cancelled") && moment(exam.exam_date).isSameOrAfter(moment(), "date") && !exam.status.includes("exam");
            }
        }).sort((a: any, b: any) => {
            return moment(a?.exam_date).isAfter(moment(b?.exam_date))
        });
        //console.log(upcomingExams[0])
        return upcomingExams[0];
    };

    const cancelTheoryExam = (id: any) => {
        Modal.confirm({
            cancelText: "Aizvērt",
            okText: "Atcelt eksāmenu",
            title: "Atcelt eksāmenu",
            content: "Vai tiešām vēlaties atcelt eksāmenu?",
            onOk: () => {
                http.post(`cancel-theory-exam/${id}`).then((response: any) => {
                    if (refreshBalance) {
                        refreshBalance();
                    }
                    refetchExams();
                })
            }
        })
    }

    // useEffect(() => {
    //     setStatus(
    //         {
    //             done: lessonsDone(),
    //             attended: lessonsAttended(),
    //             missed: lessonsMissed(),
    //             skipped: lessonSkipped(),
    //             eLearning: lessonsELearning()
    //         }
    //     )
    // }, [attendedLessons]);

    useEffect(() => {
        getFullTestExamCategories();
    }, [])

    useEffect(() => {
        const sublimedLessons = attendedLessons.concat(foreignLessons).sort((a: any, b: any) => {
            return Number(moment(a.lesson_date).format("YYYYMMDD")) - Number(moment(b.lesson_date).format("YYYYMMDD"))
        })
        // console.log(sublimedLessons)
        setNextLesson(() => {
            const nextLesson = sublimedLessons.find((lesson: any) => {
                return moment(lesson.lesson_date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day') && !lesson.skipped && !lesson.attained;
            })
            // console.log(nextLesson)
            if (nextLesson) {
                return nextLesson
            }
            return false;
        })
    }, [attendedLessons])

    useEffect(() => {
        getNextLesson(nextLesson)
    }, [nextLesson]);

    useEffect(() => {
        //console.log(theoryExams)
        if (theoryExams.length > 0) {
            setNextTheoryExam(getNextTheoryExam(theoryExams));
        } else {
            setNextTheoryExam({});
        }
    }, [theoryExams])

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            setStudentCategory(student.categories.find((el: any) => el.category_id === student.category_id))
        }
    }, [student]);

    useEffect(() => {
        // fetchStudentServices();
        getStudentAgeValidation();
    }, []);

    useEffect(() => {
    }, [nextTheoryExam])

    return (
        <Card title={<>{t('theory.theory_learning')}</>}>
            <Row gutter={20}>
                <Col span={24}>
                    {
                        attendedLessons?.length === attendedLessons?.filter((lesson: any) => lesson.attained)?.length
                            ? <></>
                            : <Card className="next-lesson" style={{ marginBottom: 10 }}>
                                <Row>
                                    <Col style={{ display: "flex", alignItems: "center" }}>
                                        <CalendarOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                    </Col>
                                    <Col flex="1">
                                        <Row>
                                            <Col span={24}>
                                                {
                                                    // nextLesson
                                                    //     ? haveElearning
                                                    //         ? eLearningModules?.studentModules?.length !== eLearningModules?.passedModules?.length
                                                    //             ? "Nav pabeigti visi e-apmācības moduļi"
                                                    //             : <NextLesson />
                                                    //         : <NextLesson />
                                                    //     : haveElearning
                                                    //         ? ""
                                                    //         : <b>Gaida apstiprinājumu</b>
                                                    nextLesson
                                                        ? <NextLesson />
                                                        : ""
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        // : <b>{t('theory.you_dont_have_group')}</b>
                    }
                    {/* {
                        allLessons.length === 0 && attendedLessons.length === 0
                            ? <b>{t('theory.theory_not_started')}</b>
                            : <></>
                    } */}
                </Col>
                <Col span={24}>
                    {
                        // status.attended !== undefined?
                        haveElearning
                            ? <ProgressReel
                                modules={eLearningModules.studentModules}
                                passedModules={eLearningModules.passedModules}
                                theoryLessons={attendedLessons}
                                foreignLessons={foreignLessons}
                            />
                            : <CheckpointHorizontal
                                total={attendedLessons}
                                // done={status.done}
                                // skipped={status.skipped}
                                // attended={status.attended}
                                foreignLessons={foreignLessons}
                            />
                        // : <></>
                    }
                </Col>
                <Col span={24}>
                    {lessonsMissed() !== undefined
                        ? lessonsMissed().map((id: any) => {
                            const foreignLesson = foreignLessons.find((el: any) => el.lesson_counter === id)
                            if (foreignLesson?.attained) {
                                return
                            }
                            if (haveElearning && foreignLesson === undefined) return;
                            return <MissedWarning
                                key={id}
                                student={student}
                                missedId={id}
                                reserved={foreignLesson}
                                getNotifications={getNotifications}
                                cities={cities}
                                refetch={refetch}
                            />
                        })
                        : <></>}
                </Col>
                {
                    !!student?.category?.has_theory_exam
                        ? nextTheoryExam?.hasOwnProperty("id")
                            ? <Col span={24}>
                                <div
                                    style={{
                                        padding: "16px 20px",
                                        backgroundColor: "#F0F2F5",
                                        borderLeft: "4px solid #DC4A26",
                                        marginTop: 20
                                    }}
                                >
                                    <h3 style={{
                                        fontSize: 16,
                                        fontWeight: "bolder"
                                    }}
                                    >
                                        {t('theory_exams.you_have_upcoming_theory_exam')} ({studentCategory?.category} {t('common.category')})
                                    </h3>
                                    <p>{moment(nextTheoryExam.exam_date).format("Do MMMM (dddd)")},&nbsp;
                                        {moment(nextTheoryExam.time_from, "HH:mm:ss").format("HH:mm")} - {moment(nextTheoryExam.time_to, "HH:mm:ss").format("HH:mm")}</p>
                                    <p>{t('theory_exams.zoom_link_will_show_up')}.</p>
                                    <p>{t('theory_exams.attention_exam_can_be_nulled')}.</p>
                                    <Row justify="space-between">
                                        {
                                            nextTheoryExam.zoom_meets.length > 0
                                                ? <a
                                                    href={nextTheoryExam.zoom_meets[0].meeting_link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Button>
                                                        {t('common.open')}
                                                    </Button>
                                                </a>
                                                : <b>{t('theory_exams.link_is_not_generated')}</b>
                                        }
                                        <Button
                                            onClick={() => cancelTheoryExam(nextTheoryExam.id)}
                                        >
                                            {t('common.cancel')}
                                        </Button>
                                    </Row>
                                    {
                                        fullTestCategory && !!nextTheoryExam?.allow_test
                                            && moment(nextTheoryExam?.exam_date, "YYYY-MM-DD").isSame(moment(), "day")
                                            && moment().isSameOrAfter(moment(nextTheoryExam?.time_from, "HH:mm:ss").subtract(5, "minutes"), "minutes")
                                            && moment().isBefore(moment(nextTheoryExam?.time_to, "HH:mm:ss").add(5, "minutes"), "minute")
                                            ? <>
                                                <Tag color="red" style={{ marginTop: 20 }}>
                                                    Eksāmens ir sācies
                                                </Tag>
                                                <Button
                                                    type="primary"
                                                    style={{ margin: 20, marginLeft: 0 }}
                                                    onClick={() => history.push(`/theory-exam/${nextTheoryExam.hash}`, { examObject: nextTheoryExam })}
                                                >
                                                    Teorijas eksāmens
                                                    <CountDownTimer
                                                        seconds={moment(nextTheoryExam.time_to, "HH:mm:ss").diff(moment(nextTheoryExam.time_from, "HH:mm:ss"), "seconds")}
                                                        start_time={moment(nextTheoryExam.time_from, "HH:mm:ss")}
                                                        // stopTimer={stopTimer}
                                                        setTimeIsUp={(timeIsUp: boolean) => { }}
                                                        returnTime={(time: string) => { }}
                                                        style={{ color: "black", marginLeft: 6 }}
                                                        staticStartTime={true}
                                                    />
                                                </Button>
                                            </>
                                            : <></>
                                    }

                                </div>
                            </Col>
                            : !!student.got_theory_finished
                                && !!student?.got_pmp
                                && !!student?.got_medical
                                && !student?.got_theory_exam_finished
                                && !theoryExams?.some((el: any) => el.status.includes("passed"))
                                && (student.csdd_medical_categories && student.csdd_medical_categories?.some((el: any) => el === student.category.value))
                                && !!studentAgeValidation.valid
                                && student?.hard !== null
                                && !!!student?.expired_theory
                                ? <Row>
                                    <Col span={24}>
                                        <div
                                            style={{
                                                padding: "16px 20px",
                                                backgroundColor: "#F0F2F5",
                                                marginTop: 20,
                                                marginBottom: 20
                                            }}
                                        >
                                            <h3 style={{
                                                fontSize: 16,
                                                fontWeight: "bolder"
                                            }}
                                            >
                                                {t('theory_exams.exam_is_available')}
                                            </h3>
                                            <p>
                                                {t('theory_exams.you_have_available_exams_text')}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Link to="/theory-exams">
                                            <Button
                                                type="primary"
                                            >
                                                {t('theory_exams.attend_for_theory_exam')}
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                : Object.keys(studentAgeValidation).length > 0 && !!!studentAgeValidation.valid && !!student.got_theory_finished && !student?.got_theory_exam_finished
                                    ? <Tag color="red" style={{ marginTop: 20 }}>
                                        {t('theory_exams.not_old_enough')}
                                    </Tag>
                                    : <></>
                        : <></>
                }
            </Row>
        </Card>
    )
}