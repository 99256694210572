import { Layout } from "antd";
import React, { useEffect } from "react";
import { LessonsHistoryTable } from "./Summary/LessonsHistoryTable";

export const DrivingHistory = (props: any) => {
    const {
        isMobile,
        studentHistory,
        studentExamHistory,
        subjects,
        examSubjects,
        studentTestDriveHistory
    } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <div>
        {
            (studentTestDriveHistory && studentTestDriveHistory.length > 0) &&
            <div style={{ marginBottom: 30 }}>
                <LessonsHistoryTable
                    isMobile={isMobile}
                    studentHistory={studentTestDriveHistory}
                    subjects={examSubjects}
                    testDrive
                    all
                />
            </div>
        }
        <div style={{ marginBottom: 30 }}>
            <LessonsHistoryTable
                isMobile={isMobile}
                studentHistory={studentExamHistory}
                subjects={examSubjects}
                exam
                all
            />
        </div>
        <LessonsHistoryTable
            isMobile={isMobile}
            studentHistory={studentHistory}
            subjects={subjects}
            all
        />
    </div>
}