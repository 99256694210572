import { Button, Card, Row, Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { LessonStatus } from "./LessonStatus";
import { MobileHistoryLessons } from "./MobileHistoryLessons";
import IcEye from "../../../img/ic-eye.svg";
import { useTranslation } from "react-i18next";

export const LessonsHistoryTable = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        subjects,
        studentHistory,
        isMobile,
        all,
        exam,
        testDrive
    } = props;

    const gradeTranslation = {
        exam_worthy: t('driving.eval.exam_worthy'),
        medium: t('driving.eval.medium'),
        beginner: t('driving.eval.beginner')
    } as any;

    const columns = [
        {
            title: t('common.date'),
            dataIndex: "lesson_date",
            key: "lesson_date",
            // render: (date: any) => {
            //     return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY")
            // }
        },
        {
            title: t('driving.eval.time'),
            key: "time",
            render: (nothing: any, record: any) => {
                const timeFrom = moment(record.time_from, "HH:mm:ss").format("HH:mm");
                const timeTo = moment(record.time_to, "HH:mm:ss").format("HH:mm");
                return `${timeFrom} - ${timeTo}`
            }
        },
        {
            title: exam || testDrive ? t('driving.eval.inspector') : t('driving.eval.instructor'),
            dataIndex: "instructor_name",
            key: "instructor_name"
        },
        {
            title: t('driving.eval.lesson_lvl'),
            dataIndex: "grade",
            key: "grade",
            render: (grade: any) => {
                return exam ? t('driving.exam') : gradeTranslation[grade]
            }
        },
        {
            title: exam ? t('driving.eval.grade') : t('driving.eval.grades'),
            render: (nothing: any, record: any) => {
                return exam
                    ? record.status.includes("passed")
                        ? <Tag color="green">{t('driving.eval.passed')}</Tag>
                        : record.status.includes("student_didnt_come")
                            ? <Tag color="red">{t('driving.student_didnt_come')}</Tag>
                            : <Tag color="red">{t('driving.eval.failed')}</Tag>
                    : <LessonStatus
                        lesson={record}
                    />
            }
        },
        {
            dataIndex: "student_lesson_id",
            render: (id: any, record: any) => {
                return record.full_eval
                    ? <Button
                        onClick={() => history.push(`/driving/stats/${id}${exam ? "/exam" : testDrive ? "/testDrive" : ""}`)}
                    >
                        {t('driving.eval.open_lesson')}
                    </Button>
                    : <></>
            }
        }
    ]

    return <Card
        bodyStyle={{
            padding: isMobile ? "12px 20px 30px" : "0 0 30px"
        }}
        title={
            <Row>
                <h3 style={{ margin: 0 }}><b>{exam ? t('driving.eval.exam_history') : testDrive ? t('driving.eval.test_drivings') : t('driving.eval.lessons_history')}</b> ({studentHistory.length})</h3>
            </Row>
        }
    >
        {
            isMobile
                ? studentHistory?.slice(0, all ? studentHistory.length : 5)?.map((lesson: any) => {
                    return <MobileHistoryLessons
                        key={lesson.lesson_id}
                        lesson={lesson}
                        translation={gradeTranslation}
                        exam={exam ? true : false}
                    />
                })
                : <Table
                    columns={columns}
                    dataSource={studentHistory?.slice(0, all ? studentHistory.length : 5)}
                    pagination={false}
                />
        }
        {
            !all
            && <Button
                type="text"
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => history.push('/driving/history')}
            >
                <img src={IcEye} />
                <span
                    style={{
                        color: "#059DC1",
                        marginLeft: 10
                    }}
                >
                    {t('driving.eval.open_all_history')}
                </span>
                &nbsp;({studentHistory.length})
            </Button>
        }
    </Card>
}