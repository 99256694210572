import { Button, Card, Col, Divider, Row } from "antd";
import { FileOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ReactComponent as IcCalendar } from "../../../img/ic-calendar.svg";
import { ReactComponent as IcUser } from "../../../img/ic-user-box.svg";
import { ReactComponent as IcLevel } from "../../../img/ic-level.svg";
import { ReactComponent as IcStar } from "../../../img/ic-star.svg";
import { EvaluationList } from "./EvaluationList";
import { MapSection } from "../MapStats/MapSection";
import { InfoBadge } from "../../components/InfoBadge";
import { useTranslation } from "react-i18next";
import { TheoryExamQuestionList } from "./TheoryExamQuestionList";
import { SpecialManeuverList } from "./SpecialManeuverList";

export const LessonInfoCard = (props: any) => {
    const { t } = useTranslation();
    const { lesson, subjects, exam, testDrive } = props;
    const [isMobile, setIsMobile] = useState(false)

    const translation = {
        beginner: t("driving.eval.beginner"),
        medium: t("driving.eval.medium"),
        exam_worthy: t("driving.eval.exam_worthy")
    } as any;

    const attachment1 = lesson.attachment && !lesson?.attachment?.includes("noimage") && lesson.attachment;
    const attachment2 = lesson.attachment2 && !lesson?.attachment2?.includes("noimage") && lesson.attachment2;
    const baseUrl = process.env.REACT_APP_API_URL + "storage/student_lesson_reports/"

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 992px)").matches)

        window.addEventListener("resize", () => {
            setIsMobile(window.matchMedia("(max-width: 992px)").matches)
        })
    }, []);

    return <Card
        title={exam ? t("driving.eval.exam_grade") : t("driving.eval.lesson_themes")}
    >
        <Row>
            <Col span={24} lg={16}>
                <Row>
                    <Col span={24}>
                        <Row gutter={[14, 14]}>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcCalendar}
                                    text={exam ? t("driving.eval.exam_date_time") : t("driving.eval.lesson_date_time")}
                                    data={`${lesson.lesson_date} ${lesson.time_from} - ${lesson.time_to}`}
                                />
                            </Col>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcUser}
                                    text={exam ? t("driving.eval.exam_evaluated_by") : t("driving.eval.eval_by")}
                                    data={lesson.instructor_name}
                                />
                            </Col>
                            {
                                exam || testDrive
                                    ? <></>
                                    : <>
                                        <Col span={24} md={12}>
                                            <InfoBadge
                                                Icon={IcLevel}
                                                text={t("driving.eval.lesson_lvl")}
                                                data={translation[lesson.grade]}
                                            />
                                        </Col>
                                        <Col span={24} md={12}>
                                            <InfoBadge
                                                Icon={IcStar}
                                                text={t("driving.eval.student_rating")}
                                                data={`${lesson.rating_improvement} punkti (${lesson.student_rating})`}
                                            />
                                        </Col>
                                    </>
                            }
                        </Row>
                    </Col>
                    {
                        exam && (attachment1 || attachment2)
                            ? <Col span={24}>
                                <h3><b>{t('common.added_files')}</b></h3>
                                {attachment1 && <a style={{ marginLeft: 10 }} href={baseUrl + attachment1} target="_blank" download><Button><FileOutlined />{t('common.file')} 1</Button></a>}
                                {attachment2 && <a style={{ marginLeft: 10 }} href={baseUrl + attachment2} target="_blank" download><Button><FileOutlined />{t('common.file')} 2</Button></a>}
                                <Divider />
                            </Col>
                            : <></>
                    }
                    {
                        lesson.feedback && lesson.feedback !== "undefined"
                        && <Col span={24}>
                            <h3><b>{t("driving.eval.instructor_comment")}</b></h3>
                            <Divider />
                            <p>{lesson.feedback}</p>
                            <Divider />
                        </Col>
                    }
                    <Col span={24}>
                        <TheoryExamQuestionList
                            lesson={lesson.student_lesson_id}
                        />
                    </Col>
                    <Col span={24}>
                        <SpecialManeuverList
                            lesson={lesson.student_lesson_id}
                        />
                    </Col>
                    <Col span={24}>
                        <EvaluationList grades={lesson.grades} subjects={subjects} exam={exam || testDrive} />
                    </Col>
                </Row>
            </Col>
            <Col span={24} lg={8} style={{ paddingLeft: isMobile ? 0 : 36 }}>
                <MapSection isMobile={isMobile} lesson={lesson} />
            </Col>
        </Row>
    </Card >
}