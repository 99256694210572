import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../components/Header";
import { LessonInfoCard } from "./LessonInfo/LessonInfoCard";

export const DrivingStats = (props: any) => {
    const { lessonID } = useParams() as any;
    const { isMobile, subjects, studentHistory, exam, testDrive } = props;
    const [lesson, setLesson] = useState({} as any);

    useEffect(() => {
        if (studentHistory.length > 0) {
            console.log(studentHistory, lessonID)
            setLesson(() => {
                return studentHistory.find((el: any) => el.student_lesson_id === Number(lessonID));
            })
        }
    }, [studentHistory]);

    useEffect(() => {
        // console.log(lesson)
    }, [lesson])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <div>
        <Header />
        {
            Object.keys(lesson).length > 0
                ? <LessonInfoCard isMobile={isMobile} lesson={lesson} subjects={subjects} exam={!!exam} testDrive={!!testDrive} />
                : <></>
        }
    </div>
}