import { Button, Card, Col, Layout, Modal, Row } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { ExamQuestion } from "./ExamQuestion";
import { ReactComponent as ICCheck } from "../../img/ic-small-check-xs.svg";
// import { ReactComponent as ICArrowLeft } from "../../img/ic-small-check-xs.svg";
// import { ReactComponent as ICArrowRight } from "../../img/ic-small-check-xs.svg";
import { ExamEndCard } from "./ExamEndCard";
import { CountDownTimer } from "./common/CountDownTimer";
import { ReactComponent as IconClock } from "../../img/ic-time-left.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const ExamPage = withRouter((props: any) => {
    const { t } = useTranslation();
    const { isOpen } = props;
    const { data, module } = props.location.state;
    const history = useHistory();
    const [questions, setQuestions] = useState([] as any);
    const [ind, setInd] = useState(-1);
    const [reviewMode, setReviewMode] = useState(false);
    const [examData, setExamData] = useState(undefined as any);
    const [timeIsUp, setTimeIsUp] = useState(false);
    const [stopTimer, setStopTimer] = useState(false);
    const [currentTime, setCurrentTime] = useState("");
    // const [loading, setLoading] = useState(true);
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        const savedQuestions = JSON.parse(window.sessionStorage.getItem('questions') as any);
        const savedInd = Number(window.sessionStorage.getItem('ind'));
        const exam = JSON.parse(window.sessionStorage.getItem('exam') as any);

        window.history.pushState({ name: "browserBack", state: { data, module } }, "on browser back click", window.location.href);

        const onBackAction = (event: any) => {
            if (event.state) {
                Modal.confirm({
                    title: "Uzmanību!",
                    content: <b>Apstiprinot, tik zaudēts eksāmena progress un mēģinājums! Vai tiešām turpināt?</b>,
                    onOk: () => { history.push('/e-learning')},
                    onCancel: () => {
                        window.history.pushState({ name: "browserBack", state: { data, module } }, "on browser back click", window.location.href);
                    }
                })
            }
        }

        window.addEventListener('popstate', onBackAction, false);
        //console.log(data)
        if (exam !== null) {
            setExamData(exam);
        }
        isOpen(true);
        if (data.questions.length > 0) {
            if (savedQuestions !== null) {
                setQuestions(savedQuestions);
                setInd(savedInd);
            } else {
                setQuestions(data.questions);
                setInd(0);
            }
        };
        setMobile(window.matchMedia("(max-width: 991px)").matches)

        window.addEventListener("resize", () => {
            setMobile(window.matchMedia("(max-width: 991px)").matches)
        })

        return () => {
            isOpen(false);
            window.sessionStorage.removeItem('questions');
            window.sessionStorage.removeItem('ind');
            window.sessionStorage.removeItem('exam');
            window.removeEventListener('popstate', onBackAction, false);
        }
    }, []);

    const submitAnswer = (result: any) => {
        setQuestions((prev: any) => {
            const newList = prev.map((item: any, index: any) => {
                if (index === ind) {
                    return {
                        ...item,
                        ...result
                    }
                } else {
                    return item
                }
            })
            window.sessionStorage.setItem('questions', JSON.stringify(newList));
            return [...newList];
        })
        if (ind < questions.length - 1) {
            window.sessionStorage.setItem('ind', String(ind + 1));
            setInd((prev: any) => prev + 1);
        } else {
            endExam();
        }
    }

    const endExam = async () => {
        await http.post(`/finish-module-exam/${data.exam.id}`).then((response: any) => {
            //console.log(response);
            setExamData(response.data.data);
            setStopTimer(true);
            window.sessionStorage.removeItem('ind');
            window.sessionStorage.setItem('exam', JSON.stringify(response.data.data));
        })
    };

    const timeIsUpHandler = () => {
        http.post(`timer-passed-finish-module-exam/${data.exam.id}`, { pc_time: moment().format("YYYY-MM-DD HH:mm:ss") })
    }

    useEffect(() => {
        if (timeIsUp) timeIsUpHandler();
    }, [timeIsUp]);

    useEffect(() => {
        if (reviewMode) {
            setInd(questions.findIndex((el: any) => !!!el.correct))
        }
    }, [reviewMode])

    return <Layout>
        <Card>
            <Row justify={mobile ? "space-between" : "start"}>
                <div style={{ borderRight: "1px solid black", padding: 15 }}>EINŠTEINS</div>
                {mobile || <div style={{ borderRight: "1px solid black", flexGrow: 5, padding: 15 }}><p>{t('e_learning.final_test')}.</p></div>}
                <div style={{ borderRight: "1px solid black", padding: 15 }}>
                    <p>{t('e_learning.question')}</p>
                    <b>{ind + 1} / {questions.length}</b>
                </div>
                <div style={{ borderRight: "1px solid black", padding: 15 }}>
                    <p>{t('e_learning.time')}</p>
                    <b>
                        {
                            examData === undefined
                                ? <CountDownTimer
                                    seconds={module.exam_time_limit * 60}
                                    start_time={moment(data.exam.started_date).format("YYYY-MM-DD HH:mm:ss")}
                                    stopTimer={stopTimer}
                                    setTimeIsUp={(timeIsUp: boolean) => setTimeIsUp(timeIsUp)}
                                    returnTime={(time: string) => setCurrentTime(time)}
                                    // staticStartTime={true}
                                />
                                : <></>
                        }

                    </b>
                </div>
            </Row>
            {
                mobile && <div>
                    <p style={{ textAlign: "center", fontWeight: "bolder", margin: "10px 0 0" }}>{t('e_learning.final_test')}.</p>
                </div>
            }
            {
                mobile && reviewMode
                    ? <div>
                        <Button onClick={() => setReviewMode(false)}>
                            {t('e_learning.back_to_summary')}
                        </Button>
                    </div>
                    : <></>
            }
        </Card>
        {
            reviewMode
                ? <Row justify="space-between" style={{ padding: "27px 24px 3px" }}>
                    {
                        mobile || <div>
                            <Button onClick={() => setReviewMode(false)}>
                                {t('e_learning.back_to_summary')}
                            </Button>
                        </div>
                    }
                    <div>
                        {
                            questions.map((question: any, index: any) => {
                                if (question?.correct) return <></>
                                return <button
                                    className={`question-bubble ${question?.correct
                                        ? "correct"
                                        : "incorrect"
                                        } ${index === ind ? "selected" : ""}`}
                                    onClick={() => setInd(index)}
                                    key={index}
                                >
                                    <ICCheck className="ic-check" />
                                    <div className="arrow-up"></div>
                                    {index + 1}
                                </button>
                            })
                        }
                    </div>
                    <div>
                        {/* <Button
                        style={{ marginRight: 4 }}
                        onClick={() => setInd((prev: any) => {
                            if (prev === 0) return 0;
                            return prev - 1;
                        })}
                    >
                        <ArrowLeftOutlined />
                    </Button>
                    <Button
                        onClick={() => setInd((prev: any) => {
                            if (prev === questions.length - 1) return questions.length - 1;
                            return prev + 1;
                        })}
                    >
                        <ArrowRightOutlined />
                    </Button> */}
                    </div>
                </Row>
                : <></>
        }
        <div className="module-exam-question-card">
            {
                !timeIsUp && questions.length > 0 && ind > -1
                    ? (examData === undefined || reviewMode)
                        ? <ExamQuestion
                            question={questions[ind]}
                            // question={undefined}
                            nextQuestion={submitAnswer}
                            exam={data.exam}
                            review={reviewMode}
                            ind={ind}
                            mobile={mobile}
                        />
                        : <ExamEndCard
                            questions={questions}
                            examData={examData}
                            reviewAnswers={() => setReviewMode(true)}
                            module={module}
                            endTime={currentTime}
                            mobile={mobile}
                        />
                    : <div>
                        <Row justify="center">
                            <Col span={12}>
                                <div className="time-is-up">
                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <div className="icon-round-wrap">
                                            <IconClock />
                                        </div>
                                    </Row>
                                    <p style={{ textAlign: "center" }}>
                                        {t('e_learning.time_left')}: <b style={{ color: "#CC3326" }}>00:00</b>
                                    </p>
                                    <h3>{t('e_learning.exam_time_expired')}.</h3>
                                    <Row justify="center">
                                        <button
                                            className="btn-submit"
                                            onClick={() => history.push(`/e-learning/exam/${module.hash_id}`)}
                                        >
                                            {t('e_learning.start_from_begining')}
                                        </button>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
            }
        </div>
    </Layout>
})