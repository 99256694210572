import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";

export const CountDownTimer = (props: any) => {
    const { seconds, stopTimer, setTimeIsUp, start_time, returnTime, staticStartTime } = props;
    const [timer, setTimer] = useState(-1);
    // const [startTime, setStartTime] = useState(moment(start_time))
    const [endTime, setEndTime] = useState(undefined as string | undefined);
    const [clockString, setClockString] = useState("" as string);
    // const [serverDiff, setServerDiff] = useState(0);
    // const [serverTime, setServerTime] = useState(undefined as string | undefined);

    const getServerTime = () => {
        if (!staticStartTime) {
            setEndTime(moment(start_time, "YYYY-MM-DD HH:mm:ss").add(seconds, 'seconds').format("YYYY-MM-DD HH:mm:ss"))
        } else {
            http.get('server-timer').then((res) => {
                // setServerDiff(moment().diff(res.data))
                // setServerTime(res.data)
                const realSeconds = seconds - moment(res.data).diff(start_time, "seconds");
                // console.log("realSeconds", realSeconds, seconds, moment().diff(start_time, "seconds"), res.data, start_time)
                setEndTime(moment().add(realSeconds, "seconds").format("YYYY-MM-DD HH:mm:ss"));
            })
        }
    }

    const calculateSecondsRemaining = (serverTime: string) => {
        http.get('server-timer').then((res) => {
            const realSeconds = seconds - moment(res.data).diff(start_time, "seconds");
            setEndTime(moment().add(realSeconds, "seconds").format("YYYY-MM-DD HH:mm:ss"));
        })
    }

    useEffect(() => {
        getServerTime();
        setTimer(seconds)
    }, [])

    // useEffect(() => {
    //     if (serverDiff && serverTime) {
    //         if (serverDiff) {
    //             console.log("Server time changed", serverDiff)
    //         }
    //     }
    // }, [serverDiff])

    useEffect(() => {
        if (endTime === undefined) return;
        const clock = setInterval(() => {
            if (stopTimer) {
                clearInterval(clock);
                //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
                return;
            };
            if (moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment()) < 0) {
                clearInterval(clock);
                //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
                setTimeIsUp(true);
                return;
            }
            setTimer(prev => prev - 1)
            let diff = Math.abs(moment().diff(moment(endTime, "YYYY-MM-DD HH:mm:ss"), "seconds"));
            // console.log("endTime", endTime, diff / 60)
            const timeFormat = diff / 60 > 60 ? "HH:mm:ss" : "mm:ss";
            setClockString(moment.utc((diff) * 1000).format(timeFormat));
            returnTime(moment.utc((diff) * 1000).format(timeFormat));
        }, 1000);
        return () => { clearInterval(clock) };
    });

    useEffect(() => {
        if (timer === 0) {
            setTimeIsUp(true);
        }
    }, [timer])

    return <b style={{ color: "#CC3326", ...props.style }}>
        {
            moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment()) <= 0
                ? "00:00"
                : clockString
        }
    </b>
}