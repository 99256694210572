import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { MainView } from './MainView';
import { CalendarView } from './CalendarView';
import { SelectInstructorView } from './SelectInstructorView';
import { PaymentHelp } from '../PaymentsPage/PaymentHelp';
import { http } from '../../helpers/http';
import { PaidPage } from '../PaymentsPage/PaidPage';
import { PaymentError } from '../PaymentsPage/PaymentError';
import { PaymentCancel } from '../PaymentsPage/PaymentCancel';

export const PlannerPage = withRouter((props: any) => {
    const { self, hasTheory } = props;
    const [student, setStudent] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [lastLesson, setLastLesson] = useState({} as any);
    const [theoryExams, setTheoryExams] = useState([] as any);
    const [studentLessons, setStudentLessons] = useState([] as any)

    const fetchAll = async () => {
        await http.get('lesson_info', { params: { category: self.category_id } })
            .then(result => {
                setLastLesson(result.data.data.last_lesson);
                setStudentLessons(result.data.data.lessons);
            });

        // await http.get('balance')
        //     .then(result => { setBalance(result.data.data); })
    }

    const fetchTheoryExamResults = () => {
        http.get(`get-theory-exam-results`).then((response: any) => {
            //console.log(response)
            const notCanceledExams = response.data.filter((exam: any) => {
                return !exam.status.includes("admin_cancelled");
            })
            setTheoryExams(response.data)
        })
    }

    useEffect(() => {
        fetchAll().then(() => {
            setLoaded(true)
        });
        fetchTheoryExamResults();
    }, []);

    useEffect(() => {
        if (self) {
            setStudent(self);
        }
    }, [self])

    useEffect(() => {
    }, [loaded])

    return (
        <Switch>
            <Route path="/planner/calendar/exam">
                <CalendarView exam self={self} refreshBalance={() => props.refreshBalance()} />
            </Route>
            <Route path="/planner/calendar/testDrive">
                <CalendarView testDrive self={self} refreshBalance={() => props.refreshBalance()} />
            </Route>
            <Route path="/planner/calendar/:instructor_id">
                {
                    loaded ?
                        <CalendarView
                            refreshBalance={() => props.refreshBalance()}
                            self={self}
                            city={lastLesson?.city_id}
                            lessons={studentLessons}
                        />
                        : <div style={{ height: "100vh" }}><Skeleton /></div>
                }
            </Route>
            <Route path="/planner/select-instructor/:testDriveCategory">
                <SelectInstructorView />
            </Route>
            <Route path="/planner/select-instructor">
                <SelectInstructorView />
            </Route>
            <Route exact path="/planner">
                {
                    loaded
                        ? <MainView
                            student={student}
                            theoryExams={theoryExams}
                            refetchExams={() => fetchTheoryExamResults()}
                            refreshBalance={() => props.refreshBalance()}
                            hasTheory={hasTheory}
                        // returnLastLesson={(lastLesson: any) => setLastLesson(lastLesson)}
                        />
                        : <Skeleton />
                }
            </Route>
            <Route path="/planner/payment-help/success/:token/:sum">
                <PaidPage />
            </Route>
            <Route path="/planner/payment-help/error/:token">
                <PaymentError />
            </Route>
            <Route path="/planner/payment-help/cancel/:token">
                <PaymentCancel />
            </Route>
            <Route path="/planner/payment-help">
                <PaymentHelp balance={props.balance} refreshBalance={() => props.refreshBalance()} student={student} />
            </Route>
        </Switch>
    )
});